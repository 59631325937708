<template>
    <div class="layout-wrapper    ">
        <div class="layout-main-container ">
            <div class="  flex align-items-center justify-content-center m  overflow-hidden">
                <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
                    <div class="col-12 mt-5 xl:mt-0 text-center">
                        <span class="text-900 text-3xl font-medium mb-3">CPC Admin Panel</span>
                    </div>
                    <div class="col-12 xl:col-6"
                        style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                        <div class="h-full w-full m-0 py-7 px-4"
                            style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                            <div class="text-center mb-5">
                                <div class="text-900 text-3xl font-medium mb-3">Login</div>
                            </div>

                            <div class="w-full md:w-10 mx-auto">
                                <div class="w-full md:w-10 mx-auto text-center mb-5">
                                    <Button label="Sign In with Google" class="w-full p-3 text-xl" @click="login"></button>
                                </div>
                                <Toast />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { decodeCredential, googleTokenLogin } from "vue3-google-login";
import { apiUrl } from "../../config/config";
import apiStore from './../store/index';
export default {
    computed: {
    },
    mounted() {
        console.log('apiUrl', apiUrl)
    },
    data() {
        return {
            email: '',
            password: '',
            checked: false
        }
    },
    methods: {
        login() {
            googleTokenLogin().then((response) => {
                if (response.access_token) {
                    var payload = { "access_token": response.access_token, "toast": this.$toast };
                    apiStore.dispatch('authentication/googleAuthCallback', payload);
                }
            })
        }
    }
}
</script>

<style scoped>
.pi-eye {
    transform: scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform: scale(1.6);
    margin-right: 1rem;
}</style>